.mat-mdc-select-panel-small-font {
  --mat-option-label-text-size: 12px;
  --mat-optgroup-label-text-size: 14px;
}

.onboarding-panel {
  position: relative !important;
}

.onboarding-panel > .mat-mdc-option {
  margin: 0 6px 0 16px;
  min-height: 24px;
  max-height: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 4px;
  position: static;
  font-size: 12px;
  color: $gray;
}

.onboarding-panel > .mat-mdc-option:focus.mdc-list-item,
.onboarding-panel > .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background: $primary !important;
}

.onboarding-panel > .mat-mdc-option:focus.mdc-list-item::before,
.onboarding-panel > .mat-mdc-option.mat-mdc-option-active.mdc-list-item::before,
.onboarding-panel > .mat-mdc-option:hover.mdc-list-item::before {
  content: '';
  width: 8px;
  position: absolute;
  height: 24px;
  background: $primary;
  left: -4px;
  border-radius: 12px;
}

.onboarding-panel > .mat-mdc-option-active > span,
.onboarding-panel > .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) > span {
  color: $defaultTextColor;
}
